'use client';

import { createTheme } from '@mantine/core';

export const theme = createTheme({
	fontFamily: 'Inter',
	colors: {
		'primary-Krmz': [
			'#F95957',
			'#FFCCCB',
			'#FFC7C6',
			'#F9595712',
			'#CD53521C',
			'#DB514F61',
			'#ED858A',
			'',
			'',
			'',
		],
		'secondary-Mor': [
			'#758BFD',
			'#B5C1FF',
			'#ECEEFF',
			'#4D5CFF',
			'#415FFF',
			'#A0C5E1',
			'#2A66AD',
			'#004687',
			'#6C5DD3',
			'',
		],
		'accent-01': ['#36393B', '#E2E2E2', '#F0F0F0', '#808191', '', '', '', '', '', ''],
		'accent-02': ['#F6AE2D', '#FFE2AD', '#FFF2DB', '#FFB27B', '#FF7F48', '', '', '', '', ''],
		'dark-grey': [
			'#1B1D21',
			'#E4E4E440',
			'#F0F3F6',
			'#E4E4E47F',
			'#E4E4E4',
			'#A0A1AB',
			'#E4E4E452',
			'',
			'',
			'',
		],
		error: ['#FF3A20', '', '', '', '', '', '', '', '', ''],
		intermediate: ['#F7CB15', '', '', '', '', '', '', '', '', ''],
		confirmation: ['#62C370', '#62C37073', '', '', '', '', '', '', '', ''],
	},
});
