import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/app/[locale]/global.css");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/dropzone/styles.css");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/core/styles.css");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/charts/styles.css");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/code-highlight/styles.css");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/notifications/esm/Notifications.mjs");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/notifications/esm/notifications.store.mjs");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/fonts.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/fonts.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/node_modules/@mantine/notifications/styles.css");
import(/* webpackMode: "eager" */ "/home/worker/videolity-app/source/theme.ts")